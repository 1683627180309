import React, { useEffect} from "react";
import { navigate } from "gatsby";
import Layout from '../components/Layout'
import SEO from '../components/SEO'

export default function Thanks() {
    // on component did mount wait 2 seconds then navigate home
    useEffect(() => {
        setTimeout(() => {
          navigate("/"); // redirecting to home page
        }, 2000)
      }, []);
    return (
        <Layout>
            <SEO title='Thank you page' description='Thanks for submitting your info!'/>
        <div className='h-screen w-full text-center pt-20'>
          <span className='mt-40'>
            <h1 className='text-white'>Thank you for your message! </h1>
            <h2>We will get back to you as soon as we can</h2>
            <p className='text-white'>You will be redirected to the home page shortly</p>
            <img style={{margin: "auto"}} src="https://www.datocms-assets.com/56195/1633674844-nom-mark-blue.svg"></img>
           </span>
        </div>
        </Layout>
    )
}

